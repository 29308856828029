<template>
    <transition
      name="zoom-fade"
      mode="out-in"
    >
        <router-view />
    </transition>
</template>

<script>

    export default {
        // metaInfo: {
        //     title: $i18n.locale == 'ru' ? 'Платформа для майнинга криптовалют | Qubity' : 'Platform for cryptocurrency mining  | Qubity',
        //     titleTemplate: null,
        //     meta: [
        //         {
        //             vmid: 'description',
        //             name: 'description',
        //             content: 'Qubity - это платформа для облачного майнинга криптовалют, где вы можете купить или арендовать хешрейт и начать зарабатывать до 150% годовых. В личном кабинете предоставляется мониторинг и аналитика ваших контрактов. На сайте представлен широкий ассортимент сертифицированного оборудования и различных инструментов для инвестирования'
        //         }
        //     ]
        // },
        metaInfo() {
            const title = this.$t('title-pages.main');
            return {
                title: title,
                titleTemplate: null,
                meta: [
                    {
                        vmid: 'description',
                        name: 'description',
                        content: 'Qubity - это платформа для майнинга криптовалют, где вы можете купить или арендовать хешрейт и начать зарабатывать до 150% годовых. В личном кабинете предоставляется мониторинг и аналитика ваших контрактов. На сайте представлен широкий ассортимент сертифицированного оборудования и различных инструментов для инвестирования'
                    }
                ]
            }
        },
        data() {
            return {}
        },
        methods: {

        },
        components: {

        },
        watch: {

        },
        mounted() {

        }

    }

</script>